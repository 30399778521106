import React, { useEffect } from "react"
import { useQuill } from "react-quilljs"
import _ from "lodash";
import Quill from "quill";
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import styles from "./styles.module.scss"

const BasicQuillEditor = ({ placeholder = " ", onQuillReady = (quill: Quill) => { }, onChange = (data) => { } }) => {
    const { quill, quillRef } = useQuill({
        theme: 'snow',
        modules: {
            toolbar: [
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [
                    'bold',
                    'italic',
                    'underline',
                    {
                        'color': [
                            'red', 'orange', 'yellow', 'green', 'blue', 'violet', false
                        ]
                    },
                    {
                        'background': [
                            'red', 'orange', 'yellow', 'green', 'blue', 'violet', false
                        ]
                    }
                ],
                [{ 'list': 'bullet' }, { 'list': 'ordered' }],
                ['link', 'image']
            ]
        },
        placeholder
    })

    useEffect(() => {
        if (_.isNil(quill)) {
            return;
        }
        console.log("[BasicQuillEditor] quill Changed", quill);
        quill.on('text-change', (delta, oldDelta, source) => {
            console.log("[BasicQuillEditor] text-change", delta, oldDelta, source);
            onChange(quill.getContents());
        })
        onQuillReady(quill);
    }, [quill])

    return (
        <div className={`${styles.qlWrapper} ${styles.customSize} focus-within:ring-2 focus-within:ring-indigo-600 rounded-md`}>
            <div ref={quillRef} />
        </div>
    )

}

export default BasicQuillEditor;
