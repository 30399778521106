import React, { useRef, useState } from "react";
import GeneralTaskList, { RangePickerOption } from "containers/Task/GeneralTaskList";
import { columnInfos } from "containers/Task/GeneralTaskList/config";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import Button from "components/Form/Button";
import { useModal } from "components/Modal/Simple";
import { randomString } from "utils/math";
import { SimpleDatePicker } from "components/Form/Datepicker";
import { adminClient } from "apis/admin";
import moment from "moment-timezone";
import _ from "lodash";
import { toast } from "react-toastify";
import Checkbox from "components/Form/Checkbox";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import { CalculatorIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import PurchaseViewer from "pages/Admin/Customer/Status/PurchaseViewer";
import { useDateDialog } from "components/Dialog/Date";
import { formatShippingCompanyName } from "../InputDeliveryInfo/helper";
import { useSelectDialog } from "components/Dialog/Select";
import { useInputDialog } from "components/Dialog/Input";
import { SHIPPING_COMPANY_ITEMS } from "pages/Admin/Customer/Status/TaskManager";

const RANGE_PICKER_OPTIONS: RangePickerOption[] = [
    {
        label: "모든 날짜",
        field: null
    },
    {
        label: "베송시작일",
        field: "deliver_at"
    }
]

const CompleteShipping = () => {
    const states: (keyof typeof CustomerState)[] = ["SHIPPING"]
    const [submissionId, setSubmissionId] = useState(null);
    const [randomKey, setRandomKey] = useState(randomString());
    const checkedIdsRef = useRef([]);
    const [checkedIds, setCheckedIds] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const { Modal: PurchaseViewerModal, openModal: openPurchaseViewerModal, closeModal: closePurchaseViewerModal } = useModal();
    const { DialogProvider, openDialog } = useDateDialog();
    const { DialogProvider: SelectShippingCompanyDialogProvider, openDialog: openSelectShippingCompanyDialog } = useSelectDialog();
    const { DialogProvider: TrackingNumberDialogProvider, openDialog: openTrackingNumberInputDialog} = useInputDialog();
    const columnInfosWithTask = [
        {
            field: "task",
            label: "고객명",
            visible: true,
            render: (value, field, row) => (
                <div className="flex space-x-1.5">
                    {/* <Button
                        onButtonClicked={() => { onCompleteButtonClicked(row) }}
                    >
                        <div className="text-xs">
                            배송완료 처리
                        </div>
                    </Button> */}
                    <Checkbox initialChecked={isAllSelected} data={row.submission_id} onChange={onCheckboxesChanged} onInitialized={onCheckboxesChanged} />
                    <div className="">{row.user_name}</div>
                </div>
            ),
            align: "center"
        },
        ...columnInfos?.map((info) => {
            if (["user_name", "nudge"].includes(info.field)) {
                return {
                    ...info,
                    visible: false
                }
            }
            if (info.field === "purchase_detail") {
                return {
                    ...info,
                    render: (value, field, row) => <CalculatorIcon className="size-4 cursor-pointer" onClick={() => { openPurchaseViewerModal(row) }}></CalculatorIcon>,
                    visible: true,
                    align: "center"
                }
            }
            if (["shipping_company"].includes(info.field)) {
                return {
                    ...info,
                    render: (value, field, row) => (
                        <div className="flex space-x-2 items-center cursor-pointer" onClick={async () => {
                            const ret = await openSelectShippingCompanyDialog("배송사 변경", SHIPPING_COMPANY_ITEMS);
                            console.log("[result]", ret);
                            if(_.isNil(ret)){
                                return;
                            }
                            const value = ret?.value;
                            try{
                                const ret = await adminClient.patch(`/trackings/${row.submission_id}/field`, {
                                    shipping_company: value
                                });
                                console.log("[ret]", ret);
                                setRandomKey(randomString());
                                toast.success("배송사가 변경되었습니다.");
                            }catch(e){
                                console.error(e);
                                toast.error("배송사 변경에 실패했습니다.");
                            }
                        }}>
                            <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                            <div>{formatShippingCompanyName(value, field, row)}</div>
                        </div>
                    )
                }
            }
            if(["tracking_number"].includes(info.field)){
                return {
                    ...info,
                    render: (value, field, row) => (
                        <div className="flex space-x-2 items-center cursor-pointer" onClick={async () => {
                            const result = await openTrackingNumberInputDialog("송장번호 입력", value);
                            console.log("[result]", result);
                            if (_.isNil(result)) {
                                return;
                            }
                            try{
                                const ret = await adminClient.patch(`/trackings/${row.submission_id}/field`, {
                                    tracking_number: result
                                });
                                console.log("[ret]", ret);
                                setRandomKey(randomString());
                                toast.success("송장번호가 변경되었습니다.");
                            }catch(e){
                                console.error(e);
                                toast.error("송장번호 변경에 실패했습니다.");
                            }
                        }}>
                            <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                            <div>{value}</div>
                        </div>
                    )
                }
            }
            return info;
        })
    ]

    const onCompleteButtonClicked = async (row) => {
        // setSubmissionId(submissionId);
        console.log("[onCompleteButtonClicked] row", row);
        const ok = window.confirm("배송완료 처리하시겠습니까?");
        if (!ok) {
            return;
        }
        try {
            const body = {
                deliveredDate: new Date()
            }
            const ret = await adminClient.patch(`/trackings/${row.submission_id}/delivered`, body);
            console.log("[onCompleteButtonClicked] ret", ret);
            toast.success("배송완료 처리되었습니다.");
            setRandomKey(randomString());
        } catch (e) {
            console.error(e);
            toast.error("배송완료 처리에 실패했습니다.");
        }
    }

    const onCheckboxesChanged = (checked, submissionId) => {
        console.log("[onCheckboxesChanged] checked", checked, "submissionId", submissionId)
        const newCheckedDatas = _.uniq([...checkedIdsRef.current]);
        if (checked) {
            newCheckedDatas.push(submissionId);
        } else {
            _.remove(newCheckedDatas, (elm) => elm === submissionId);
        }
        console.log("[onCheckboxesChanged] newCheckedDatas", newCheckedDatas);
        checkedIdsRef.current = newCheckedDatas;
        setCheckedIds(newCheckedDatas);
    }

    const onMultiCompleteButtonClicked = async () => {
        console.log("[onMultiCompleteButtonClicked] checkedDatas", checkedIdsRef.current);
        const checkedIds = checkedIdsRef.current;
        if (checkedIds.length === 0) {
            toast.warn("선택된 항목이 없습니다.");
            return;
        }
        ///trackings/delivered/multiple
        const deliveredDate = await openDialog("배송완료일", new Date());
        if (_.isNil(deliveredDate)) {
            return;
        }
        const date = moment(deliveredDate).format("YYYY-MM-DD");
        const ok = window.confirm(`선택된 항목을 배송완료 처리하시겠습니까? (배송완료일: ${date})`);
        if (!ok) {
            return;
        }
        const body = {
            submission_ids: checkedIds,
            deliveredDate
        }
        try {
            const ret = await adminClient.patch(`/trackings/delivered/multiple`, body);
            console.log("[onMultiCompleteButtonClicked] ret", ret);
            const { successSubmissionIds, failedSubmissionIds } = ret.data;
            if (_.isEmpty(successSubmissionIds)) {
                throw new Error("일괄 배송완료 처리에 실패했습니다.");
            }
            toast.success(`${successSubmissionIds.length}건이 배송완료 처리되었습니다.`);
            if (!_.isEmpty(failedSubmissionIds)) {
                toast.error(`${failedSubmissionIds.length}건이 배송완료 처리에 실패했습니다.`);
            }
            setRandomKey(randomString());
        } catch (e) {
            console.error(e);
            toast.error("일괄 배송완료 처리에 실패했습니다.");
        }
        // setRandomKey(randomString());
    }

    return (
        <>
            <Card>
                <div className="p-3">
                    <PageTitle>배송완료 처리</PageTitle>
                    <div className="mb-2">
                        <GeneralTaskList
                            states={states}
                            columnInfos={columnInfosWithTask}
                            rangePickerOptions={RANGE_PICKER_OPTIONS}
                            enableIntakeFilter={false}
                            enableClinicFilter={true}
                            enableShippingCompanyFilter={true}
                            randomKeyExternal={randomKey}
                        >
                            <div className="flex justify-end space-x-2">
                                <div>
                                    <Button
                                        onButtonClicked={() => { setIsAllSelected(!isAllSelected); setRandomKey(randomString()) }}
                                    >
                                        {isAllSelected ? "전체선택 해제" : "전체선택"}
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onButtonClicked={onMultiCompleteButtonClicked}
                                        disabled={_.isEmpty(checkedIds)}
                                    >
                                        배송완료 처리
                                    </Button>
                                </div>
                            </div>
                        </GeneralTaskList>
                    </div>
                </div>
            </Card>
            <PurchaseViewerModal>
                <PurchaseViewer />
            </PurchaseViewerModal>
            <DialogProvider />
            <SelectShippingCompanyDialogProvider/>
            <TrackingNumberDialogProvider/>
        </>


    )
}

export default CompleteShipping;
