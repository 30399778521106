import React from "react"
import SideNav from "components/SideNav"

const menus = [
    {
        title: "의료진",
        to: "/doctor",
        subMenus: [
            {
                title: "처방전 작성",
                to: "/doctor/prescription"
            },
            {
                title: "진료 상태",
                to: "/doctor/status"
            },
            {
                title: "판매 내역",
                to: "/doctor/sales"
            },
            {
                title: "매출 통계",
                to: "/doctor/stats"
            },
        ],
    },
    {
        title: "계정",
        to: "/account",
        subMenus: [
            {
                title: "내 계정",
                to: "/account/my-account"
            },
        ],
    }
]

export default () => {
    return (
        <SideNav menus={menus}></SideNav>
    )
}