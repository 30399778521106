import React from "react";
import { Column } from "components/Table/Simple";
import { formatDate, formatDateTime, truncate } from "components/Table/Simple/presets";
import { formatStatus } from "../Status/config";

export const columnInfos: Column[] = [
    {
        field: "_id",
        label: "id",
        visible: false
    },
    {
        field: "submission_id",
        label: "응답지번호",
        visible: true,
    },
    {
        field: "user_name",
        label: "고객명",
        visible: true,
    },
    {
        field: "clinic_user_id",
        label: "고객번호",
        visible: true,
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: true,
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
        render: truncate
    },
    {
        field: "clinic_id",
        label: "의원ID",
        visible: true,
    },
    {
        field: "intake_id",
        label: "진료지ID",
        visible: true,
    },
    {
        field: "state",
        label: "상태",
        visible: true,
        render: formatStatus
    },
    {
        field: "unpaid_noti",
        label: "미납알림",
        visible: false,
    },
    {
        field: "awaiting_at",
        label: "구매대기시작일",
        visible: true,
        render: formatDate
    },
    {
        field: "date1",
        label: "1차 연락일",
        visible: true,
    },
    {
        field: "noti1",
        label: "1차 연락여부",
        visible: true,
        align: "center",
    },
    {
        field: "memo1",
        label: "1차 연락메모",
        visible: true,
    },
    {
        field: "date2",
        label: "2차 연락일",
        visible: true,
    },
    {
        field: "noti2",
        label: "2차 연락여부",
        visible: true,
        align: "center",
    },
    {
        field: "memo2",
        label: "2차 연락메모",
        visible: true,
    },
    {
        field: "date3",
        label: "3차 연락일",
        visible: true,
    },
    {
        field: "noti3",
        label: "3차 연락여부",
        visible: true,
        align: "center",
    },
    {
        field: "memo3",
        label: "3차 연락메모",
        visible: true,
    },
    {
        field: "address",
        label: "회원주소",
        visible: false,
    },
    {
        field: "intake_version",
        label: "진료지버전",
        visible: false,
    },
    {
        field: "purchase_at",
        label: "구매일",
        visible: false,
    },
    {
        field: "delivered_at",
        label: "배송일",
        visible: false,
    },
    {
        field: "tracking_number",
        label: "송장번호",
        visible: false,
    },
    {
        field: "created_at",
        label: "생성일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "마지막 수정시간",
        visible: true,
        render: formatDateTime
    },
    {
        field: "shipping_address",
        label: "배송지",
        visible: false,
    },
    {
        field: "deliver_request_at",
        label: "배송접수일",
        visible: false,
    },
    {
        field: "deliver_at",
        label: "배송시작일",
        visible: false,
    },
    {
        field: "shipping_company",
        label: "배송사",
        visible: false,
    },
    {
        field: "nudge",
        label: "확인중",
        visible: false,
    },
    {
        field: "nudge_memo",
        label: "확인중메모",
        visible: false,
    },
    {
        field: "submission_translated_at",
        label: "응답지 번역일",
        visible: false,
    },
    {
        field: "purchase_failure_reason",
        label: "구매실패사유",
        visible: false,
    }
];