import React, { useEffect, useState } from "react";
import { useModal } from "components/Modal/Simple";
import SignInContainer from "containers/Member/SignUp";
import LogInContainer from "containers/Member/LogIn";
import lineLogo from "assets/logo/line.svg";
import nonMemberLogo from "assets/logo/non-member.png";
import { toast } from "react-toastify";
import { useTranslation } from "utils/translate";
import { CommonClient, commonClient } from "apis/common";
import _ from "lodash";
import { memberClient } from "apis/member";
import { useNavigate } from "react-router-dom";
import { handleLoginMessage } from "pages/Account/Login";
import { getLineLoginRedirectUrl } from "utils/auth";
import BasicArticle from "components/Article/Basic";
import { PencilIcon } from "@heroicons/react/24/outline";

export default ({
    onLoginSuccess = async () => { return 0 } }
) => {
    const { tr } = useTranslation();
    const { Modal, openModal, closeModal, isOpen } = useModal({ styles: { minWidth: "30vw" } });
    const [isSignUp, setIsSignUp] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('message', (e) => {
            const ok = handleLoginMessage(e);
            if (!ok) {
                return;
            }
            onLoginSuccess();
        });
    }, [])

    const onLineIdSubmittedUsingTempId = async () => {
        const tempId = localStorage.getItem("temp_id");
        if (_.isNil(tempId)) {
            toast.error("temp_id를 찾을 수 없습니다.");
            return;
        }
        console.log("[onLineIdSubmittedUsingTempId] tempId", tempId);
        const STATE = tempId;
        const redirectUrl = getLineLoginRedirectUrl(STATE);
        console.log("[onLineIdSubmittedUsingTempId] redirectUrl", redirectUrl);
        const popupWindow = window.open(redirectUrl, 'newWindow', 'width=600, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
    }

    return (
        <div>
            <div className="mx-auto max-w-3xl mt-3 mb-3">
                <div className="flex flex-col justify-between space-y-3 md:flex-row md:space-y-0 md:space-x-3">
                    <div className="border shadow rounded-md p-3 w-full text-center font-semibold hover:bg-gray-100">
                        <div className="flex justify-center" onClick={() => {
                            // onLineIdSubmitted()
                            onLineIdSubmittedUsingTempId();
                        }}>
                            <img src={lineLogo} alt="line-logo" className="w-7 h-7 mr-2" />
                            <div className="self-center">
                                {tr("라인 아이디 제출")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <BasicArticle>
                        <span style={{ fontFamily: "none" }}>
                            {"⚠️ "}
                        </span>
                        <span>
                            {tr("LINE으로 로그인해 주시는 것으로 제출을 완료하겠습니다.")}
                        </span>
                    </BasicArticle>
                    <div className="mt-1"></div>
                    <BasicArticle>
                        <div>
                            {tr("MIVIEW 공식 ")}
                            <span className="text-red-500">
                                {tr("LINE 친구추가로 첫회 500포인트(500엔 상당)")}
                            </span>
                            {tr(" 선물중")}
                            <span>
                                {"🎁✨"}
                            </span>
                        </div>
                        <div>
                            {tr("당일 이용 가능한 포인트를 이번 기회에 GET하세요!")}
                        </div>
                    </BasicArticle>
                </div>
                <div className="border-b pb-1">
                </div>
                <div className="flex pt-1">

                    <h1 className="text-sm text-blue-600 cursor-pointer" onClick={() => {
                        localStorage.clear();
                        window.location.reload();
                    }}>{`${tr("처음부터 작성하기")}`}</h1>
                </div>
            </div>
        </div>
    )
}
