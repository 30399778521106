import React, { useEffect, useState } from "react";
import { AdminClient, adminClient } from "apis/admin";
import Card from "components/Card";
import PagingTable from "components/Table/Paging";
import SimpleTable, { RowInfo } from "components/Table/Simple";
import { columnInfos } from "./config";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import Checkbox from "components/Form/Checkbox";
import { formatDate } from "components/Table/Simple/presets";
import { DateEditForm, MemoEditForm } from "../Unpaid";
import { useModal } from "components/Modal/Simple";
import { convertClinicIdColumnSelectable, createRowInfoWithClinic } from "pages/Admin/Clinic/helper";
import { toast } from "react-toastify";
import PageTitle from "components/Title/Page";
import Select, { getUidOfItems } from "components/Form/Select";
import RangeDatePicker from "components/Form/Datepicker/Range";
import ToggleSearchInput from "components/Form/Input/ToggleSearch";
import moment from "moment";
import MultiSelect from "components/Form/MultiSelect";
import { STICKY_COL_OPTION_LABEL, TABLE_OPTION_FILTER } from "../Status";
import BadgeWithRemove from "components/Badges/BadgeWithRemove";
import { randomString } from "utils/math";

const DATE_RANGE_OPTIONS = [
    {
        label: "모든 날짜",
        field: null
    },
    {
        label: "배송완료일",
        field: "delivery_complete_date"
    },
    {
        label: "복용완료일",
        field: "consumption_begin_date"
    },
    {
        label: "1차 관리 연락일",
        field: "management_date1"
    },
    {
        label: "2차 관리 연락일",
        field: "management_date2"
    },
    {
        label: "3차 관리 연락일",
        field: "management_date3"
    },
    {
        label: "1차 사후관리 연락일",
        field: "post_management_date1"
    },
    {
        label: "2차 사후관리 연락일",
        field: "post_management_date2"
    },
]

const AdminCustomerAftercarePage = () => {
    const [filterParams, setFilterParams] = useState(null);
    const [intakeInfos, setIntakeInfos] = useState(null);
    const [intakeItems, setIntakeItems] = useState(null);
    const [selectedIntakeItem, setSelectedIntakeItem] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selectedDateRangeField, setSelectedDateRangeField] = useState(DATE_RANGE_OPTIONS[0].field);
    const [selectedDateRange, setSelectedDateRange] = useState(null);

    const [clinicInfos, setClinicInfos] = useState([]);
    const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const { Modal: DateEditModal, openModal: openDateEditModal, closeModal: closeDateEditModal } = useModal();
    const { Modal: MemoEditModal, openModal: openMemoEditModal, closeModal: closeMemoEditModal } = useModal();
    const [refreshKey, setRefreshKey] = useState(randomString());
    const [isStickyFirstCol, setIsStickyFirstCol] = useState(true);

    useEffect(() => {
        fetchIntakeInfos();
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos);
        })
    }, [])

    useEffect(() => {
        console.log("[useEffect] intakeInfos", intakeInfos);
        if (_.isNil(intakeInfos)) {
            return;
        }
        const tempIntakeItems = [
            { name: "모든 진료지", value: null },
            null,
            ...intakeInfos.filter((elm) => elm.clinic_id !== "COMMON").map((elm) => ({ name: elm.title, value: elm.intake_id }))
        ]
        setIntakeItems(tempIntakeItems);
        const uid = getUidOfItems(tempIntakeItems);
        const selectedIntakeSaved = localStorage.getItem(uid);
        if (_.isNil(selectedIntakeSaved)) {
            setSelectedIntakeItem(tempIntakeItems[0]);
            return;
        }
    }, [intakeInfos])

    useEffect(() => {
        console.log("[useEffect] selectedIntakeItem", selectedIntakeItem);
        if (_.isNil(selectedIntakeItem)) {
            return;
        }
        if (_.isNil(selectedDateRange)) {
            return;
        }
        setFilterParams({
            ...(!_.isNil(searchText) && { search_text: searchText }),
            ...(!_.isNil(selectedIntakeItem.value) && { intake_id: selectedIntakeItem.value }),
            ...(!_.isNil(selectedDateRange) && {
                ...(!_.isNil(selectedDateRangeField) && { [`${selectedDateRangeField}_start`]: selectedDateRange[0] }),
                ...(!_.isNil(selectedDateRangeField) && { [`${selectedDateRangeField}_end`]: selectedDateRange[1] }),
            }),
        })
    }, [selectedIntakeItem, selectedDateRangeField, selectedDateRange, searchText])

    useEffect(() => {
        console.log("[useEffect] filterParams", filterParams);
        if (_.isNil(filterParams)) {
            return;
        }
        setRefreshKey(randomString());
    }, [filterParams])

    const fetchTableData = async (page: number, limit: number) => {
        console.log("[PointHistory] fetchTableData", page, limit);
        try {
            const params = {
                page,
                limit,
                ...filterParams
            }
            const ret = await adminClient.get(`/aftercares`, { params });
            console.log("[fetchTableData] ret", ret);
            if (!ret?.data?.data) {
                return { data: [], page: 1, total: 0 }
            }
            return {
                data: ret.data.data,
                page: ret.data.page,
                total: ret.data.total,
            }
        } catch (e) {
            console.error("[fetchTableData] error", e);
            return { data: [], page: 1, total: 0 }
        }
    }

    const fetchIntakeInfos = async () => {
        try {
            const ret = await adminClient.get(`/intakes/distinct/latest`);
            const data = ret.data;
            setIntakeInfos(data);
        } catch (e) {
            console.error("[fetchIntakeInfos] error", e);
        }
    }

    const onEditFormButtonClicked = (row, field) => {
        setSelectedSubmissionId(row.submission_id);
        setSelectedData(row[field]);
        setSelectedField(field);
    }
    const confirmCheckboxChange = (value, data) => {
        // return window.confirm("변경하시겠습니까?");
        return true;
    }

    const onCheckboxChange = async (value, data) => {
        console.log("[handleCheckboxChange]", value, data.row, data.field);
        const { row, field } = data;
        const body = {
            [`${field}`]: value
        }
        console.log("[onCheckboxChange] body", body);
        try {
            const ret = await adminClient.put(`/aftercares/${row.submission_id}`, body);
            toast.success("변경되었습니다.");
        } catch (e) {
            toast.error("변경에 실패했습니다.");
            setRefreshKey(randomString());
        }
        // setRefreshKey(randomString());
    }

    const onEditFormSubmit = async (id, field, newData) => {
        console.log("[onEditFormSubmit]", id, field, newData);
        if (field === "consumption_begin_date") {
            onComsumptionDateSubmit(id, field, newData);
            return;
        }
        // const ok = window.confirm("수정하시겠습니까?");
        // if (!ok) {
        //     return;
        // }
        const body = {
            [`${field}`]: newData
        }
        try {
            const ret = await adminClient.put(`/aftercares/${id}`, body);
            toast.success("수정되었습니다.");
            setRefreshKey(randomString());
            closeDateEditModal();
            closeMemoEditModal();
        } catch (e) {
            toast.error("수정에 실패했습니다.");
        }
    }

    const onComsumptionDateSubmit = async (id, field, newData) => {
        console.log("[onComsumptionDateSubmit]", id, field, newData);
        // const ok = window.confirm("복용완료일을 수정하시겠습니까?");
        // if (!ok) {
        //     return;
        // }
        try {
            const body = {
                [`${field}`]: newData
            }
            const ret = await adminClient.put(`/aftercares/${id}/consumption`, body);
            console.log("[onComsumptionDateSubmit] ret", ret);
            toast.success("수정되었습니다.");
            setRefreshKey(randomString());
            closeDateEditModal();
            closeMemoEditModal();
        } catch (e) {
            toast.error("수정에 실패했습니다.");
        }
    }

    const onDateRangeFieldChanged = (fieldName) => {
        console.log("[onDateRangeFieldChanged] fieldName", fieldName);
        setSelectedDateRangeField(DATE_RANGE_OPTIONS.find((elm) => elm.label === fieldName).field);
    }

    const onDateRangeChanged = (range) => {
        console.log("[onDateRangeChanged] range", range)
        if (range.some((elm) => _.isNil(elm))) {
            console.log("[useEffect] some of range has nil")
            return;
        }
        setSelectedDateRange([moment(range[0]).format("YYYY-MM-DD"), moment(range[1]).format("YYYY-MM-DD")]);
    }


    const columnInfosEditForms = columnInfos.map((elm) => {
        if (["noti1", "noti2", "noti3", "post_noti1", "post_noti2"].includes(elm.field)) {
            return {
                ...elm,
                render: (value, field, row) => <Checkbox
                    initialChecked={value}
                    confirmChange={confirmCheckboxChange}
                    onChange={onCheckboxChange}
                    data={{ row, field }}
                />,
            }
        }
        if (["consumption_begin_date", "delivery_complete_date", "management_date1", "management_date2", "management_date3", "post_management_date1", "post_management_date2"].includes(elm.field)) {
            return {
                ...elm,
                render: (value, field, row) => (
                    <div className="flex space-x-2 items-center cursor-pointer" onClick={() => { onEditFormButtonClicked(row, field); openDateEditModal() }}>
                        <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                        <div>{formatDate(value)}</div>
                    </div>
                )
            }
        }
        if (["memo1", "memo2", "memo3", "post_memo1", "post_memo2"].includes(elm.field)) {
            return {
                ...elm,
                render: (value, field, row) => (
                    <div className="flex space-x-2 items-center cursor-pointer" onClick={() => { onEditFormButtonClicked(row, field); openMemoEditModal() }}>
                        <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                        <div>{value}</div>
                    </div>
                )
            }
        }
        if (elm.field === "intake_id") {
            return {
                ...elm,
                label: "진료지제목",
                render: (value, field, row) => {
                    const intakeInfo = intakeInfos?.find((elm) => elm.intake_id === value);
                    return <div>{intakeInfo?.title}</div>
                }
            }
        }
        return elm;
    })

    const onTableOptionFilterChanged = (itemNames: string[]) => {
        console.log("[onTableOptionFilterChanged] itemNames", itemNames)
        const tempSticky = itemNames.includes(STICKY_COL_OPTION_LABEL);
        setIsStickyFirstCol(tempSticky);
    }


    const columnInfosWithClinic = convertClinicIdColumnSelectable(columnInfosEditForms, clinicInfos);
    const rowInfoWithClinic: RowInfo = createRowInfoWithClinic(clinicInfos);

    const columnInfoWithSticky = [
        {
            field: "customer_uid",
            label: "고객명",
            visible: isStickyFirstCol,
            render: (value, field, row) => {
                const style = rowInfoWithClinic?.rowStyle(row);
                const bgColor = style?.backgroundColor;
                return (
                    <div className="flex space-x-1">
                        <div className="">{row.user_name}</div>
                        {row.clinic_user_id &&
                            <BadgeWithRemove label={row.clinic_user_id} isRemovable={false} bgColor={bgColor ?? ""}></BadgeWithRemove>
                        }
                    </div>)
            }
        },
        ...columnInfosWithClinic?.map((info) => {
            if (["user_name", "clinic_user_id"].includes(info.field)) {
                return {
                    ...info,
                    visible: !isStickyFirstCol
                }
            }
            return info;
        })
    ]

    return (
        <>
            <Card>
                <div className="p-3">
                    <PageTitle>복용 후 관리</PageTitle>
                    <div className="px-2">
                        <div className="flex justify-between space-x-2 overflow-auto">
                            <div className="flex space-x-2 pb-2">
                                <div>
                                    <Select
                                        upperLabel="진료지"
                                        items={intakeItems}
                                        selectedItem={selectedIntakeItem}
                                        onChange={setSelectedIntakeItem}
                                        saveSelectedItems={true}
                                    />
                                </div>
                                <div className="z-50">
                                    <RangeDatePicker
                                        upperLabel="날짜"
                                        options={DATE_RANGE_OPTIONS.map((elm) => elm.label)}
                                        onRangeChanged={onDateRangeChanged}
                                        onOptionChanged={onDateRangeFieldChanged}
                                        hideRange={_.isNil(selectedDateRangeField)}
                                        saveOption={true}
                                        saveSelectedDts={true}
                                    />
                                </div>
                                <div className="min-w-36">
                                    <ToggleSearchInput
                                        upperLabel="고객검색"
                                        upButtonLabel="적용"
                                        downButtonLabel="해제"
                                        onToggle={(isUp, value) => { setSearchText(!isUp ? value : null) }}
                                        placeholder="고객명, 고객번호, 전화번호 검색"
                                    />
                                </div>
                            </div>
                            <div>
                                <MultiSelect
                                    upperLabel="테이블 옵션"
                                    label={TABLE_OPTION_FILTER.label}
                                    items={TABLE_OPTION_FILTER.options}
                                    onChange={onTableOptionFilterChanged}
                                    enableSelectAll={false}
                                    enableSearch={false}
                                    saveSelectedItems={true}
                                />
                            </div>
                        </div>
                        <PagingTable
                            fetchData={fetchTableData}
                            columns={columnInfoWithSticky}
                            rowInfo={rowInfoWithClinic}
                            isStickyFirstCol={isStickyFirstCol}
                            refreshKey={refreshKey}
                            windowSize={10000}
                        />
                    </div>
                </div>
            </Card>
            <DateEditModal>
                <DateEditForm id={selectedSubmissionId} field={selectedField} currentDt={selectedData} onSubmit={onEditFormSubmit} ></DateEditForm>
            </DateEditModal>
            <MemoEditModal>
                <MemoEditForm id={selectedSubmissionId} field={selectedField} currentMemo={selectedData} onSubmit={onEditFormSubmit} ></MemoEditForm>
            </MemoEditModal>
        </>
    )
}

export default AdminCustomerAftercarePage;
