import { doctorClient } from "apis/doctor";
import Card from "components/Card";
import MonthWithStep from "components/Form/Datepicker/MonthWithStep";
import SimpleTable from "components/Table/Simple";
import PageTitle from "components/Title/Page";
import _ from "lodash";
import moment from "moment";
import { statColumnInfos } from "pages/Admin/Customer/PurchaseInfo/config";
import React, { useEffect, useState } from "react";

const DoctorStatPage = () => {
    const [tableData, setTableData] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [summaryTableData, setSummaryTableData] = useState(null);

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (_.isNil(selectedMonth)) {
            return;
        }
        fetchData();
    }, [selectedMonth])

    const fetchData = async () => {
        try {
            const selectedDt = new Date(selectedMonth);
            console.log("[fetchData], selectedDt", selectedDt);
            const params = {
                startDate: moment(selectedDt).startOf('month').format("YYYY-MM-DD"),
                endDate: moment(selectedDt).endOf('month').format("YYYY-MM-DD")
            }
            const ret = await doctorClient.get('/public/purchases/statistics/monthly', { params });
            console.log("[fetchData] ret", ret);
            setTableData(ret.data);
            let total: any = {};
            ret.data.forEach((data) => {
                total = {
                    daily_total_purchase: (total.daily_total_purchase ?? 0) + data.daily_total_purchase,
                    daily_total_refund: (total.daily_total_refund ?? 0) + data.daily_total_refund,
                    daily_total_used_point: (total.daily_total_used_point ?? 0) + data.daily_total_used_point,
                    daily_total_earned_point: (total.daily_total_earned_point ?? 0) + data.daily_total_earned_point,
                }
            })
            console.log("!!! TOTAL", total);
            setSummaryTableData([total]);
        } catch (e) {
            console.error("[PurchaseStatViewer] e", e);
        }
    }

    // if (_.isNil(tableData)) {
    //     return <SpinnerMedium />
    // }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>매출 통계</PageTitle>
                <div className="flex space-x-2 pb-2 overflow-auto">
                    <div className="z-40">
                        <MonthWithStep onDtChanged={setSelectedMonth} />
                    </div>
                </div>
                <h1 className="text-sm font-semibold">
                    월 합계
                </h1>
                <SimpleTable data={summaryTableData} columns={statColumnInfos.filter((info) => ["daily_total_purchase", "daily_total_refund", "daily_total_used_point", "daily_total_earned_point"].includes(info.field))} />
                <h1 className="text-sm font-semibold">
                    일별 데이터
                </h1>
                <SimpleTable data={tableData} columns={statColumnInfos} />
            </div>
        </Card>
    )
}

export default DoctorStatPage;