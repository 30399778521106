

// intake_id	user_id	answer_sections	submission_id	created_at	updated_at

import { Column } from "components/Table/Simple";
import { formatDate, formatDateTime, truncate } from "components/Table/Simple/presets";
import { formatProductName } from "pages/Admin/Task/InputPurchaseInfo/Editor/helper";

export const columnInfos: Column[] = [
    {
        field: "submission_id",
        label: "응답지번호",
        visible: true,
    },
    {
        field: "user_name",
        label: "고객명",
        visible: true,
    },
    {
        field: "clinic_user_id",
        label: "고객번호",
        visible: true,
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: true,
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
        render: truncate
    },
    {
        field: "clinic_id",
        label: "의원ID",
        visible: true,
    },
    {
        field: "intake_id",
        label: "진료지ID",
        visible: true,
    },
    {
        field: "product_id",
        label: "제품ID",
        visible: false,
    },
    {
        field: "products",
        label: "구매제품",
        visible: true,
        render: (value, field, row) => {
            // console.log("[columnInfos] render products", value, field, row);
            return row?.products?.filter((product) => product?.name).map((product) => formatProductName(product)).join(", ");
        }
    },
    {
        field: "delivery_complete_date",
        label: "배송완료일",
        visible: true,
        render: formatDate
    },
    {
        field: "aftercare_name",
        label: "복용후 연락 유형",
        visible: true,
    },
    {
        field: "consumption_begin_date",
        label: "복용시작일",
        visible: true,
        render: formatDate
    },
    {
        field: "management_date1",
        label: "1차 관리 연락일",
        visible: true,
        render: formatDate
    },
    {
        field: "noti1",
        label: "1차 관리 연락여부",
        visible: true,
        align: "center",
    },
    {
        field: "memo1",
        label: "1차 관리 메모",
        visible: true,
    },
    {
        field: "management_date2",
        label: "2차 관리 연락일",
        visible: true,
        render: formatDate
    },
    {
        field: "noti2",
        label: "2차 관리 연락여부",
        visible: true,
        align: "center",
    },
    {
        field: "memo2",
        label: "2차 관리 메모",
        visible: true,
    },
    {
        field: "management_date3",
        label: "3차 관리 연락일",
        visible: true,
        render: formatDate
    },
    {
        field: "noti3",
        label: "3차 관리 연락여부",
        visible: true,
        align: "center",
    },
    {
        field: "memo3",
        label: "3차 관리 메모",
        visible: true,
    },
    {
        field: "post_management_date1",
        label: "1차 사후관리 연락일",
        visible: true,
        render: formatDate
    },
    {
        field: "post_noti1",
        label: "1차 사후관리 연락여부",
        visible: true,
        align: "center",
    },
    {
        field: "post_memo1",
        label: "1차 사후관리 메모",
        visible: true,
    },
    {
        field: "post_management_date2",
        label: "2차 사후관리 연락일",
        visible: true,
        render: formatDate
    },
    {
        field: "post_noti2",
        label: "2차 사후관리 연락여부",
        visible: true,
        align: "center",
    },
    {
        field: "post_memo2",
        label: "2차 사후관리 메모",
        visible: true,
    },
    {
        field: "purchase_at",
        label: "구매일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "마지막 수정시간",
        visible: true,
        render: formatDateTime
    },
    {
        field: "_id",
        label: "ID",
        visible: false,
    },
    {
        field: "aftercare_type_id",
        label: "사후관리타입ID",
        visible: false,
    },
];